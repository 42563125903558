import Vue from 'vue'
import VueRouter from 'vue-router'
/**
 * 重写路由的push方法
 */
const routerPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(error => error)
}

// 路由懒加载
const Authorization = () => import('../components/Authorization.vue')
const Login = () => import('../views/Login.vue')
const Index = () => import('../views/Index.vue')
const Welcome = () => import('../views/auth/index/Welcome.vue')
const RoleMenu = () => import('../views/auth/roleMenu/RoleMenu.vue')
const employeeManagement = () => import('../views/auth/employee/EmployeeManagement.vue')
const employeeDetail = () => import('../views/auth/employee/EmployeeDetail.vue')
const OrganizationManagement = () => import('../views/auth/organization/OrganizationManagement.vue')
const Post = () => import('../views/auth/post/Post.vue')
const Menu = () => import('../views/auth/menu/Menu.vue')
const Authority = () => import('../views/auth/authority/Authority.vue')
const OrganizationTypeMaintenance = () => import('../views/auth/organizationType/OrganizationType.vue')
const myAccountView = () => import('../views/auth/my/MyAccountView.vue')
const SystemConfig = () => import('../views/systemConfig/SystemConfig.vue')
const SystemConfigDetails = () => import('../views/systemConfig/SystemConfigDetails.vue')
const Member = () => import('../views/member/Member.vue')
const MemberDetails = () => import('../views/member/MemberDetails.vue')
const Course = () => import('../views/course/Course.vue')
const CourseDetails = () => import('../views/course/CourseDetails.vue')
const Product = () => import('../views/product/Product.vue')
const ProductDetails = () => import('../views/product/ProductDetails.vue')
const Order = () => import('../views/order/Order.vue')
const OrderDetails = () => import('../views/order/OrderDetails.vue')
const Audio = () => import('../views/audio/Audio.vue')
const AudioDetails = () => import('../views/audio/AudioDetails.vue')
const Agent = () => import('../views/agent/Agent.vue')
const AgentDetails = () => import('../views/agent/AgentDetails.vue')
const Baby = () => import('../views/baby/Baby.vue')
const BabyDetails = () => import('../views/baby/BabyDetails.vue')
const Carousel = () => import('../views/carousel/Carousel.vue')
const CarouselDetails = () => import('../views/carousel/CarouselDetails.vue')
const Activity = () => import('../views/activity/Activity.vue')
const ActivityDetails = () => import('../views/activity/ActivityDetails.vue')
const AgentGarden = () => import('../views/agentGarden/AgentGarden.vue')
const AgentGardenDetails = () => import('../views/agentGarden/AgentGardenDetails.vue')
const Syllabus = () => import('../views/syllabus/Syllabus.vue')
const SyllabusDetails = () => import('../views/syllabus/SyllabusDetails.vue')
const SyllabusType = () => import('../views/syllabusType/SyllabusType.vue')
const SyllabusTypeDetails = () => import('../views/syllabusType/SyllabusTypeDetails.vue')
const PromptCode = () => import('../views/promptCode/PromptCode.vue')
const PromptCodeDetails = () => import('../views/promptCode/PromptCodeDetails.vue')
const FamilyLearning = () => import('../views/familyLearning/FamilyLearning.vue')
const FamilyLearningDetails = () => import('../views/familyLearning/FamilyLearningDetails.vue')
const CourseType = () => import('../views/courseType/CourseType.vue')
const CourseTypeDetails = () => import('../views/courseType/CourseTypeDetails.vue')
const ProductType = () => import('../views/productType/ProductType.vue')
const ProductTypeDetails = () => import('../views/productType/ProductTypeDetails.vue')
const AudioType = () => import('../views/audioType/AudioType.vue')
const AudioTypeDetails = () => import('../views/audioType/AudioTypeDetails.vue')
const Activation = () => import('../views/activation/Activation.vue')
const ActivationDetails = () => import('../views/activation/ActivationDetails.vue')
const Exam = () => import('../views/exam/Exam.vue')
const ExamDetails = () => import('../views/exam/ExamDetails.vue')
const ExamSubclass = () => import('../views/examSubclass/ExamSubclass.vue')
const ExamSubclassDetails = () => import('../views/examSubclass/ExamSubclassDetails.vue')
const ExamConfig = () => import('../views/examConfig/ExamConfig.vue')
const ExamConfigDetails = () => import('../views/examConfig/ExamConfigDetails.vue')
const PathUrl = () => import('../views/pathUrl/PathUrl.vue')
const PathUrlDetails = () => import('../views/pathUrl/PathUrlDetails.vue')

const AccountActivation = () => import('../views/accountActivation/AccountActivation.vue')
const AccountActivationDetails = () => import('../views/accountActivation/AccountActivationDetails.vue')
const Playground = () => import('../views/playground/Playground.vue')
const PlaygroundDetails = () => import('../views/playground/PlaygroundDetails.vue')
const PlaygroundChapter = () => import('../views/playgroundChapter/PlaygroundChapter.vue')
const PlaygroundChapterDetails = () => import('../views/playgroundChapter/PlaygroundChapterDetails.vue')
const PlaygroundType = () => import('../views/playgroundType/PlaygroundType.vue')
const PlaygroundTypeDetails = () => import('../views/playgroundType/PlaygroundTypeDetails.vue')

const Holidays = () => import('../views/holidays/Holidays.vue')
const HolidaysDetails = () => import('../views/holidays/HolidaysDetails.vue')
const HolidaysType = () => import('../views/holidaysType/HolidaysType.vue')
const HolidaysTypeDetails = () => import('../views/holidaysType/HolidaysTypeDetails.vue')
const Homeland = () => import('../views/homeland/Homeland.vue')
const HomelandDetails = () => import('../views/homeland/HomelandDetails.vue')
const HomelandType = () => import('../views/homelandType/HomelandType.vue')
const HomelandTypeDetails = () => import('../views/homelandType/HomelandTypeDetails.vue')
const Kindergarten = () => import('../views/kindergarten/Kindergarten.vue')
const KindergartenDetails = () => import('../views/kindergarten/KindergartenDetails.vue')
const KindergartenType = () => import('../views/kindergartenType/KindergartenType.vue')
const KindergartenTypeDetails = () => import('../views/kindergartenType/KindergartenTypeDetails.vue')
const TeacherWorld = () => import('../views/teacherWorld/TeacherWorld.vue')
const TeacherWorldDetails = () => import('../views/teacherWorld/TeacherWorldDetails.vue')
const TeacherWorldType = () => import('../views/teacherWorldType/TeacherWorldType.vue')
const TeacherWorldTypeDetails = () => import('../views/teacherWorldType/TeacherWorldTypeDetails.vue')

Vue.use(VueRouter)

const routes = [
  { path: '/', redirect: '/authorization' },
  { path: '/authorization', component: Authorization },
  { path: '/login', component: Login },
  {
    path: '/index',
    component: Index,
    redirect: '/Welcome',
    children: [
      { path: '/index', component: Welcome },
      { path: '/employee', component: employeeManagement },
      { path: '/employee/details', component: employeeDetail },
      { path: '/organization', component: OrganizationManagement },
      { path: '/post', component: Post },
      { path: '/menu', component: Menu },
      { path: '/authority', component: Authority },
      { path: '/organizationType', component: OrganizationTypeMaintenance },
      { path: '/myAccountView', component: myAccountView },
      { path: '/roleMenu', component: RoleMenu },
      { path: '/systemConfig', component: SystemConfig },
      { path: '/systemConfig/details', component: SystemConfigDetails },
      { path: '/member', component: Member },
      { path: '/member/details', component: MemberDetails },
      { path: '/course', component: Course },
      { path: '/course/details', component: CourseDetails },
      { path: '/product', component: Product },
      { path: '/product/details', component: ProductDetails },
      { path: '/order', component: Order },
      { path: '/order/details', component: OrderDetails },
      { path: '/audio', component: Audio },
      { path: '/audio/details', component: AudioDetails },
      { path: '/agent', component: Agent },
      { path: '/agent/details', component: AgentDetails },
      { path: '/baby', component: Baby },
      { path: '/baby/details', component: BabyDetails },
      { path: '/carousel', component: Carousel },
      { path: '/carousel/details', component: CarouselDetails },

      { path: '/activity', component: Activity },
      { path: '/activity/details', component: ActivityDetails }, { path: '/agentGarden', component: AgentGarden },
      { path: '/agentGarden/details', component: AgentGardenDetails },
      { path: '/syllabus', component: Syllabus },
      { path: '/syllabus/details', component: SyllabusDetails },
      { path: '/syllabusType', component: SyllabusType },
      { path: '/syllabusType/details', component: SyllabusTypeDetails },
      { path: '/promptCode', component: PromptCode },
      { path: '/promptCode/details', component: PromptCodeDetails },
      { path: '/familyLearning', component: FamilyLearning },
      { path: '/familyLearning/details', component: FamilyLearningDetails },
      { path: '/courseType', component: CourseType },
      { path: '/courseType/details', component: CourseTypeDetails },
      { path: '/productType', component: ProductType },
      { path: '/productType/details', component: ProductTypeDetails },
      { path: '/audioType', component: AudioType },
      { path: '/audioType/details', component: AudioTypeDetails },
      { path: '/activation', component: Activation },
      { path: '/activation/details', component: ActivationDetails },
      { path: '/exam', component: Exam },
      { path:'/exam/details',component:ExamDetails },
      { path: '/examSubclass', component: ExamSubclass },
      { path:'/examSubclass/details',component:ExamSubclassDetails },
      { path: '/examConfig', component: ExamConfig },
      { path:'/examConfig/details',component:ExamConfigDetails },
      { path: '/pathUrl', component: PathUrl },
      { path:'/pathUrl/details',component:PathUrlDetails },
      { path: '/accountActivation', component: AccountActivation },
      { path:'/accountActivation/details',component:AccountActivationDetails },
      { path: '/playground', component: Playground },
      { path:'/playground/details',component:PlaygroundDetails },
      { path: '/playgroundChapter', component: PlaygroundChapter },
      { path:'/playgroundChapter/details',component:PlaygroundChapterDetails },
      { path: '/playgroundType', component: PlaygroundType },
      { path:'/playgroundType/details',component:PlaygroundTypeDetails },
 
      { path: '/holidays', component: Holidays },
      { path:'/holidays/details',component:HolidaysDetails },
      { path: '/holidaysType', component: HolidaysType },
      { path:'/holidaysType/details',component:HolidaysTypeDetails },
      { path: '/homeland', component: Homeland },
      { path:'/homeland/details',component:HomelandDetails },
      { path: '/homelandType', component: HomelandType },
      { path:'/homelandType/details',component:HomelandTypeDetails },
      { path: '/kindergarten', component: Kindergarten },
      { path:'/kindergarten/details',component:KindergartenDetails },
      { path: '/kindergartenType', component: KindergartenType },
      { path:'/kindergartenType/details',component:KindergartenTypeDetails },
      { path: '/teacherworld', component: TeacherWorld },
      { path:'/teacherWorld/details',component:TeacherWorldDetails },
      { path: '/teacherWorldType', component: TeacherWorldType },
      { path:'/teacherWorldType/details',component:TeacherWorldTypeDetails },
  
  
  

    ]
  }

]

const router = new VueRouter({
  routes
})

// 挂载路由导航守卫,to表示将要访问的路径，from表示从哪里来，next是下一个要做的操作 next('/login')强制跳转login
router.beforeEach((to, from, next) => {

  if (to.path === '/login' || to.path === '/authorization') {

    next();

  } else {

    let token = "";//token保存在localstorage中

    let item = localStorage.getItem('token');
    // 先将拿到的试着进行json转为对象的形式
    try {
      item = JSON.parse(item);
    } catch (error) {
      // eslint-disable-next-line no-self-assign
      item = item;
    }
    if (item && item.startTime) {
      let date = new Date().getTime();
      // 如果大于就是过期了，如果小于或等于就还没过期
      let timel = date - item.startTime;
      console.log('timelength:' + timel + ",expires:" + item.expires)
      if (timel < item.expires) {
        token = item.value;
      }
    }
    if (token === null || token === '') {
      next('/login');
    } else {
      next();
    }
  }
})

export default router
